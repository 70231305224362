<template>
  <section class="all-cases-view" id="all-received-sendOuts">
    <div>
      <div class="container-default">
        <div class="listings-navbar">
          <el-row>
            <el-col :lg="7">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li v-if="hasPermission('cases', 'create')">
                  <el-button
                    class="btn button-with-icon"
                    @click="onReportsExport"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/export-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>Export</span>
                  </el-button>
                </li>
                <li>
                  <div class="form-element input-with-icon search-input-lg">
                    <el-input
                      placeholder="SendoutId / Patient Name"
                      v-model="search_string"
                      @input="getAutoCompleteSendOuts()"
                      :clearable="true"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
            <el-col :lg="17">
              <ul
                class="d-flex flex-wrap justify-content-end list-items-group-15"
              >
                <li>
                  <div
                    class="form-element search-with-filter-input-without-icon"
                  >
                    <el-select
                      v-model="searchBy"
                      clearable
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      placeholder="Select Search"
                      @change="clearInputSearch"
                    >
                      <el-option
                        v-for="item in searchOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </li>

                <li v-if="searchBy == 'Patient DOB'">
                  <div class="search-with-date-input">
                    <el-date-picker
                      format="MM-DD-YYYY"
                      v-model="date_of_birth"
                      :disabled-date="disabledDate"
                      @change="fetchAllReceivedSendOuts()"
                      placeholder="Select DOB"
                      :clearable="true"
                    ></el-date-picker>
                  </div>
                </li>
                <li v-else-if="searchBy == 'case_types'">
                  <div
                    class="form-element search-with-filter-input"
                    v-if="
                      getAuthenticatedUser.user_type == 'LAB_TECHNOLOGIST' &&
                      getAuthenticatedUser.allowed_case_types
                    "
                  >
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getUserBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                  <div class="form-element search-with-filter-input" v-else>
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getLabBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-default" style="padding-top: 0">
      <div class="primary-content-view-new" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            @sort-change="onChangeSort"
            :data="getAllReceivedSendOuts.data"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            style="width: 100%"
          >
            <el-table-column
              fixed="left"
              prop="sendout_id"
              label="SENDOUT"
              min-width="150"
              sortable="custom"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span id="accession-id" @click="onView(scope.row)">
                    {{ scope.row.sendout_id }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              fixed="left"
              prop="accession_id"
              label="ACCESSION ID"
              min-width="130"
              sortable="custom"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span id="accession-id-blue">
                    {{ scope.row.accession_id }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Case Type"
              min-width="125"
              prop="case_types"
              sortable="custom"
            >
              <template #default="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}

                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                        >
                          {{ caseType }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div v-else class="case-type">
                  <span class="case-data-type">
                    {{ getCaseTypes(scope.row.case_types)[0] }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="patient_info.first_name"
              label="PATIENT NAME"
              min-width="140"
              sortable="custom"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="getPatientNameByInfo(scope.row.patient_info)"
                  @click="
                    copyTitle(getPatientNameByInfo(scope.row.patient_info))
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    getPatientNameByInfo(scope.row.patient_info)
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="Patient DOB" min-width="120">
              <template #default="scope">
                {{ formatDate(scope.row.patient_info.date_of_birth) }}
              </template>
            </el-table-column>

            <el-table-column
              prop="hospitals"
              label="ORDERING FACILITIES"
              min-width="215"
            >
              <template #default="scope">
                <div v-if="scope.row.hospital">
                  <el-button
                    placement="top"
                    class="title-on-hover"
                    :title="scope.row.hospital ? scope.row.hospital.name : '--'"
                  >
                    <span class="text-center ordering_facility-value">{{
                      scope.row.hospital.name
                    }}</span>
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="physician" label="physician" min-width="150">
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes('Dr')
                        ? scope.row.ordering_physician.name
                        : 'Dr ' + scope.row.ordering_physician.name
                      : '--'
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes("Dr")
                        ? scope.row.ordering_physician.name
                        : "Dr " + scope.row.ordering_physician.name
                      : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="CREATED ON"
              min-width="130"
              prop="collection_date"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.collection_date">
                  <div class="d-flex">
                    <div class="date pr-5">
                      {{
                        momentWithTimezone(
                          scope.row.collection_date,
                          "MM-DD-yyyy"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Received On"
              min-width="130"
              prop="received_date"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.received_date">
                  <div class="d-flex">
                    <div class="date pr-5">
                      {{
                        momentWithTimezone(
                          scope.row.received_date,
                          "MM-DD-yyyy"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              min-width="130"
              sortable="custom"
              prop="status"
            >
              <template v-slot="scope">
                <div class="column-status">
                  <span
                    class="el-dropdown-link"
                    v-if="scope.row.status && scope.row.status == 'COMPLETE'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)">
                      FINALIZED
                    </el-tag>
                  </span>
                  <span class="el-dropdown-link" v-else-if="scope.row.status">
                    <el-tag :type="getStatusType(scope.row.status)">{{
                      scope.row.status.split("_").join(" ")
                    }}</el-tag>
                  </span>
                  <el-tag
                    v-else-if="
                      getAuthenticatedUser.user_type === 'HOSPITAL_PHYSICIAN'
                    "
                    :type="getStatusType(scope.row.status)"
                    >{{
                      scope.row.status === "COMPLETE"
                        ? scope.row.status
                        : "PROCESSING"
                    }}</el-tag
                  >

                  <el-tag v-else :type="getStatusType(scope.row.status)">{{
                    scope.row.status.split("_").join(" ")
                  }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="REPORTED ON"
              min-width="130"
              prop="reported_at"
              sortable="custom"
            ></el-table-column>

            <el-table-column
              prop="action"
              label="Actions"
              width="90"
              fixed="right"
            >
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li @click="onView(scope.row)">
                    <el-button
                      class="view-button"
                      title="View Received Sendout"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>

                  <li>
                    <el-button
                      class="delete-button"
                      @click="onDelete(scope.row._id)"
                      v-if="hasPermission('cases', 'delete')"
                      title="Delete Received Sendout"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/delete-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/delete-deactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="listings listings__pagination">
            <el-pagination
              v-model:currentPage="page"
              v-model:page-size="pageSize"
              :page-sizes="[10, 25, 50, 100]"
              layout="prev, pager, next, jumper,sizes, total"
              :total="getAllReceivedSendOuts.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <SendoutsExport
      v-if="exportSendoutsDialogVisisble"
      v-on:close="exportSendoutsDialogVisisble = false"
      :search_string="search_string"
      :search_dob="date_of_birth"
      :search_caseType="caseType"
      :search_status="selectedStatuses"
    >
    </SendoutsExport>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import caseConstants from "@/config/constants/caseConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import FilterHelper from "@/mixins/FilterHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
const SendoutsExport = defineAsyncComponent(() =>
  import("@/components/sendOuts/ReceivedSendOutsExport")
);
import { receivedSendOutSearchOptions } from "@/config/constants/searchConstants";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  name: "hospital",
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      // searchByKey: ["PatientName", "sendout_id"],
      scrollPosition: null,
      searchOptions: receivedSendOutSearchOptions,
      hospital: "",
      date_of_birth: "",
      physician: "",
      searchBy: "",
      caseType: "",
      exportSendoutsDialogVisisble: false,
      page: 1,
      pageSize: 25,
      caseTypes: caseConstants.CASE_TYPES_DATA,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      search_string: "",
      loading: false,
      orderBy: "",
      orderType: "",
      hospitalsLoading: false,
      physiciansLoading: false,
      physiciansData: {},
    };
  },
  components: { SendoutsExport },
  mixins: [UserPermissionsHelper, FilterHelper, caseDataHelper],
  props: {
    selectedStatuses: {
      default: [],
    },
  },
  computed: {
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETE") {
          return "success";
        } else if (status == "TEST_REPORTS") {
          return "warning";
        } else if (status == "ARCHIVED") {
          return "danger";
        } else {
          return "";
        }
      };
    },

    ...mapGetters("sendOuts", [
      "getAllReceivedSendOuts",
      "getSendOutDeleteStatus",
      "getSendOutDeleteError",
    ]),

    ...mapGetters("auth", ["getAuthenticatedUser"]),
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
  },
  mounted() {
    this.setQueryParamsData();
    this.fetchAllReceivedSendOuts();
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    clearInputSearch() {
      if (this.date_of_birth || this.caseType) {
        this.date_of_birth = "";
        this.caseType = "";
        this.isSearchByInput = "";
        this.page = 1;
        this.fetchAllReceivedSendOuts(1);
      }
    },
    async updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getAutoCompleteSendOuts() {
      setTimeout(() => {
        this.page = 1;
        this.fetchAllReceivedSendOuts();
      }, 600);
    },

    onView(sendOutInfo) {
      this.$router.push({
        name: "ReceivedSendOutView",
        params: { send_out_id: sendOutInfo._id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    async onReportsExport() {
      this.loading = true;

      this.exportSendoutsDialogVisisble = true;
      this.loading = false;
    },
    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedString) {
        this.search_string = this.$route.query.selectedString;
      }
      if (this.$route.query.selectedCase) {
        this.caseType = this.$route.query.selectedCase;
      }
      if (this.$route.query.selectedSearchBy) {
        this.searchBy = this.$route.query.selectedSearchBy;
      }
      if (this.$route.query.selectedDateOfBirth) {
        this.date_of_birth = this.$route.query.selectedDateOfBirth;
      }
      if (this.$route.query.selectedOrderBy) {
        this.orderBy = this.$route.query.selectedOrderBy;
      }
      if (this.$route.query.selectedOrderType) {
        this.orderType = this.$route.query.selectedOrderType;
      }
    },
    async onDelete(id) {
      this.$confirm("Are you sure to delete send-out?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteSendOut(id);
      });
    },
    async deleteSendOut(id) {
      try {
        await this.$store.dispatch("sendOuts/deleteSendOut", {
          send_out_id: id,
        });
        if (this.getSendOutDeleteStatus) {
          successNotification("Send-out deleted successfully");

          this.loading = true;

          await this.fetchAllReceivedSendOuts();

          this.loading = false;
        } else {
          errorNotification(
            this.getSendOutDeleteError || "Error while deleting Send-Out"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "";
        this.orderType = "";
      }
      this.fetchAllReceivedSendOuts();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllReceivedSendOuts();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllReceivedSendOuts();
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.date_of_birth) {
        const date_of_birth = moment(this.date_of_birth).format("YYYY-MM-DD");

        params.date_of_birth = date_of_birth;
      }

      if (this.caseType) {
        params.case_type = this.caseType;
      }

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      params.status = this.selectedStatuses;
      return params;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,
        selectedCase: params.case_type,
        selectedDateOfBirth: params.date_of_birth,
        selectedString: params.search_string,
        selectedPageSize: params.limit,
        selectedOrderBy: params.order_by,
        selectedOrderType: params.order_type,
        selectedSearchBy: this.searchBy || undefined,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    async fetchAllReceivedSendOuts() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("sendOuts/fetchReceivedSendOuts", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#all-received-sendOuts {
  .stickyNavbar {
    padding-bottom: 5px !important;
    .container-default {
      padding-top: 0;
      padding-bottom: 5px;
    }
  }
}
</style>
